import styled from "styled-components"

export default styled.div`
p {
  font-size: 17px;
  line-height: 36px;
  margin: 0 0 20px;
  color: #535763;
}
`
